import React from "react";
import "./thread&fabricMaincontent.css";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const ThreadFabricMaincontent = () => {
  return (
    <ParallaxProvider>
      <div className="responsive-Thread-Fabric">
        <br />

        <div style={{ marginTop: "100px" }}>
          <div className="two-column">
            <div className="column-2">
              {/* Column 1 */}

              <div className="responsive-Thread-pc">
                <ParallaxBanner style={{ aspectRatio: "2 / 2", width: "100%" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/thread-and-fabric/tile-01.webp"
                    speed={7}
                  />
                </ParallaxBanner>
              </div>
              <div className="responsive-Thread-mobile">
                <ParallaxBanner
                  style={{ aspectRatio: "1 / 1.5", width: "100%" }}
                >
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/thread-and-fabric/tile-01.webp"
                    speed={10}
                  />
                </ParallaxBanner>
              </div>
              <br />
              <h1 className="topic">LN Thread</h1>
              <p className="para">
                Yet another addition to the manufacturing product portfolio of
                Lalan Group, LN Thread commenced operations in 2015. The growing
                volumes of Sri Lanka's apparel industry presented the
                opportunity to provide a range of thread solutions to the
                specialized and mid-tier segments. Our STANDARD 100 by
                OEKO-TEX&reg; certified operation is equipped to produce over
                2.5 million cones per annum. Our in-house dying and laboratory
                facilities enable us to create samples within 24 hours for
                approval. This quick response combined with our decades of
                manufacturing excellence, enables us to be flexible and offer
                customers an absolute service. <br />
                <br />
                Products: <br />
                LN SPUN <br />
                60/2 Tkt 180 - 40/3 Tkt 75 - 60/3 Tkt 120 <br />
                20/2 Tkt 50 - 50/2 Tkt 140 <br />
                40/2 Tkt 120s - 20/3 Tkt 30 <br />
                <br />
                LN Core <br />
                59/2 Tkt 180 - 29/2 Tkt 75 - 45/2 Tkt 120 <br />
                <br />
                LN Stretch <br />
                T/160 - 150D <br />
                <br />
                LN Embroidery <br />
                120 D <br />
                <br />
              </p>

              <p className="address">
                Lalan Rubbers (Private) Limited - LN Thread <br />
                95B, Zone A, Biyagama EPZ, <br />
                Malwana, <br />
                Sri Lanka. <br />
                (+94) 11 461 2102/106 <br />
                saleslnt@lalangroup.com <br />
                <br />
              </p>
            </div>

            <div className="column-2">
              {/* Column 2 */}

              <br />
              <br />
              <br />
              <div className="responsive-Thread-pc">
                <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/thread-and-fabric/tile-02.webp"
                    speed={10}
                  />
                </ParallaxBanner>
              </div>
              <div className="responsive-Thread-mobile">
                <ParallaxBanner style={{ aspectRatio: "1 / 1.5" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/thread-and-fabric/tile-02.webp"
                    speed={10}
                  />
                </ParallaxBanner>
              </div>
              <br />
              <h1 className="topic">Lalan Fabric</h1>
              <p className="para">
                As the apparel industry began to dominate as the key Sri Lankan
                export, we stayed true to Lalan's roots and ventured into
                trading once again by establishing Lalan Fabric in 2002. <br />
                <br />
                Product: Fabric <br />
                Knits <br />
                Denim <br />
                Corduroy <br />
                Woven (Poly/viscose, Poly/Wool) <br />
                <br />
                Since inception, our dedicated and passionate team have
                painstakingly cultivated and expanded relationships with reputed
                textiles mills in India. The prosperity of these relationships
                has led to us being nominated as exclusive agents for globally
                renowned textile houses and suppliers. <br />
                <br />
                Exclusive agent for the following Indian Mills. <br />
                Arvind Ltd <br />
                Bhasker Denim Industries Ltd <br />
                Jain cord Industries Pvt Ltd <br />
                Reliance Industries <br />
                Nahar Industries Enterprises <br />
                Nahar Textile Ltd <br />
                Nahar Spring Mill Ltd <br />
                <br />
                Our continued quality-first attitude, transparency, and
                reliability have attracted the patronage of many customers over
                the years, as well nominated manufacturers of globally
                recognised brands such as Marks and Spencer, Sainsbury, George,
                Tesco, and Next to mention a few. <br />
                <br />
              </p>

              <p className="address">
                Lalan Fabric (Private) Limited <br />
                133, Kynsey Road, <br />
                Colombo 08, <br />
                Sri Lanka. <br />
                Email: dilip1@lalangroup.com <br />
                (+94) 11 434 5323 <br />
                (+94) 77 741 8171 <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="responsive-Thread-pc">
          <center>
            <h1 className="bigHugeTitle">
              9 Sectors <br />
              of Integrated <br />
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </h1>
            <div style={{ marginTop: "100px", marginBottom: "200px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div style={{ width: "2px", background: "#46708a" }}></div>
              </div>
            </div>
          </center>
        </div>

        <div
          className="responsive-Thread-mobile"
          style={{ marginTop: "200px", marginBottom: "300px" }}
        >
          <p className="text-max-responsive-Thread">
            9 Sectors of Integrated
            <span
              style={{
                color: "#46708a",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Expertise
            </span>
          </p>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default ThreadFabricMaincontent;
